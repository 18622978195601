.page-header {
  text-align: center;
}

.page-header-title {
  text-transform: uppercase;
  letter-spacing: 0.0125em;
  background-color: $page-header-bg;
  color: $page-header-color;
  border-radius: 2em;
  // max-width: 50%;
  // margin-left: auto;
  // margin-right: auto;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: inline-block;

  @include media-breakpoint-up("lg") {
    display: block;
    max-width: 50%;
  }
}

body.page .page-header {
  z-index: 1;
  position: relative;
  margin-bottom: -2.5rem;
}
