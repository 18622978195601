// Brand Colours
// ====================================

$purple: #9d5fa7;
$green: #008655;
$orange: #e97724;
$red: #c02d3c;
$yellow: #efb321;
$blue: #2a71b9;
$medium: #d9d9d5;
$light: #eaeae4;
$dark: #46494a;

// $purple-dark: #794981;

$white: #fff;
$black: #000;

// Bootstrap Overrides
// ====================================

$primary: $purple;
$secondary: $orange;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'dark': $dark,
  'light': $light,
  'purple': $purple,
  'green': $green,
  'orange': $orange,
  'red': $red,
  'yellow': $yellow,
  'blue': $blue,
  'white': $white,
  'black': $black,
);

$body-bg:                   $purple;
// $body-color:                $white;

$hr-border-color: $light;

// Fonts
$font-family-base: 'Darwin Pro Round';

$font-size-base: 1.125rem;

$font-weight-base: 300;

$headings-margin-bottom:      0.75rem;
// $headings-font-family:        null;
$headings-font-weight:        900;
$headings-line-height:        1;
// $headings-color:              null;

$display1-size:               3.75rem;
$display2-size:               3rem;
$display3-size:               4.5rem;
$display4-size:               1.75rem;

$display1-weight:             100;
$display2-weight:             100;
$display3-weight:             100;
$display4-weight:             100;
$display-line-height:         1.2;

$lead-font-size:              1.5rem;
$lead-font-weight:            200;
// $lead-line-height:            1.3;

// Border Radius
$border-radius:    1.125rem;
$border-radius-sm: 0.5rem;
$border-radius-md: $border-radius;
$border-radius-lg: 1.75rem;
$border-radius-xl: 2rem;

$border-radiuses: (
  md: $border-radius,
  sm: $border-radius-sm,
  lg: $border-radius-lg,
  xl: $border-radius-xl
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 1.6,
  3: $spacer * 1.25,
  4: $spacer * 1.875,
  5: $spacer * 5,
);

// Box Shadow
$box-shadow:                  0 0.5rem 1rem rgba($black, 0.15), 0 0.125rem 0.125rem rgba($black, 0.1);
$box-shadow-sm:               0 0.125rem 0.25rem rgba($black, 0.2), 0 0.125rem 0.125rem rgba($black, 0.1);
$box-shadow-lg:               0 0.5rem 0.875rem rgba($black, 0.2), 0 0.125rem 0.125rem rgba($black, 0.1);
$box-shadow-inset:            inset 0 1px 2px rgba($black, 0.075);

// Buttons
$btn-font-weight: 700;

$btn-padding-y:    0.425rem;
$btn-padding-x:    1.125rem;
// $btn-padding-y-sm: 0.25rem;
$btn-padding-x-sm: 0.625rem;
// $btn-padding-y-lg: 0.5rem;
$btn-padding-x-lg: 1.25rem;

$btn-border-width: 0;

$btn-border-radius:    2em;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

// Cards
$card-border-radius: 1.125rem;
$card-border-width: 0;
$card-box-shadow: $box-shadow-lg; // custom

// Modals
$modal-content-bg:                  transparent;
$modal-content-border-color:        transparent;
$modal-content-border-width:        0;
$modal-content-border-radius:       0;
$modal-content-inner-border-radius: 0;
$modal-content-box-shadow-xs:       none;
$modal-content-box-shadow-sm-up:    none;

$modal-backdrop-bg:                 $white;
$modal-backdrop-opacity:            1;

$close-text-shadow:                 none;

// Alerts
$alert-border-width: 0;
$alert-bg-level: 0;
$alert-color-level: -10;

$alert-padding-y:                   map-get($spacers, 3);
$alert-padding-x:                   map-get($spacers, 4);

// Custom
// ====================================

// Primary Navigation
$nav-primary-link-color:          $white;
$nav-primary-link-hover-color:    $yellow;
$nav-primary-link-disabled-color: mix($purple, $light, 50%);

// Prev/next Button
$btn-prev-next-color:          $white;
$btn-prev-next-hover-color:    $yellow;
$btn-prev-next-disabled-color: mix($purple, $light, 50%);

// Breadrumbs Navigation
$breadcrumbs-link-color:          $yellow;
$breadcrumbs-link-hover-color:    $orange;
$breadcrumbs-static-color:        $white;

// Page Header
$page-header-bg: $yellow;
$page-header-color: $white;
