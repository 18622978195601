// Make a combination BS container/row/column for each direct decendant of the
// block editor. .alignfull will be 100% width at all times
body .entry-content,
body .page-content,
body .front-page-content {
  /**
  * Block Alignment
  */
  > *:not(.alignfull),
  > * > .wp-block-group__inner-container > *:not(.alignfull) {
    @include make-container();

    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        max-width: $container-max-width;
      }
    }

    @include media-breakpoint-up('md') {
      width: percentage(8 / $grid-columns);
    }

    @include media-breakpoint-up('xl') {
      width: percentage(6 / $grid-columns);
    }
  }

  .wp-block-group__inner-container > .wp-block-quote,
  .wp-block-group__inner-container > .alignwide,
  .wp-block-separator.is-style-wide,
  > .alignwide {
    width: percentage(12 / $grid-columns) !important;

    @include media-breakpoint-up('xl') {
      width: percentage(10 / $grid-columns) !important;
    }
  }

  /**
  * Block Spacing
  */
  > {
    .wp-block-columns,
    .wp-block-media-text,
    .wp-block-group,
    figure.wp-block-image {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem;
    }
  }

  > *:last-child:not(.alignfull) {
    margin-bottom: 60px;
  }
}

.wp-block-cover__inner-container {
  @include make-container();

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width;
    }
  }
}

/**
 * Block editor color palette utilities
 * @see https://git.io/JeyD6
 */

@each $color-name, $color-value in $theme-colors {
  .has-#{$color-name}-color {
    color: $color-value;
  }

  .has-#{$color-name}-background-color {
    background-color: $color-value;
  }
}

.has-background.alignfull {
  margin-right: -$grid-gutter-width / 2;
  margin-left: -$grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
}

.wp-block-group {
  &.has-background {
    .wp-block-columns {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.is-style-stretched {
    height: 100%;
  }
}

.wp-block-group__inner-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.page.media {
  display: initial;
}

.wp-block-image figcaption {
  @extend .caption;
}

.wp-block-image img,
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  height: auto;
  width: 100%;
}

.wp-block-image.is-resized img {
  height: auto;
  width: auto;
}

.wp-block-image,
.wp-block-media-text .wp-block-media-text__media img {
  border-radius: $border-radius-lg;
}

.wp-block-image.alignfull {
  border-radius: 0;
}

.wp-block-separator {
  position: relative;
  border: 0;
  height: 2px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: theme-color('border');

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: $grid-gutter-width;
    bottom: 0;
    right: $grid-gutter-width;
    background-color: currentColor;
  }

  &.is-style-dots {
    height: auto;

    &::after {
      content: none;
    }
  }
}

.blocks-gallery-grid {
  margin-bottom: -10px;

  .blocks-gallery-item {
    margin: 0 10px 10px 0;

    @media ( max-width: 599px ) {
      max-width: calc(50% - 1em);
    }

    .blocks-gallery-item:last-child {
      margin-right: 0;
    }

    figure {
      align-items: center;
    }
  }
}

.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

// @include media-breakpoint-up('md') {
//   .wp-block-column:not(:first-child) {
//     margin-left: 15px;
//   }
// }

.wp-block-cover__inner-container {
  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wp-block-column {
  margin-bottom: 15px;

  .wp-block-cover__inner-container {
    a {
      @extend .stretched-link;
    }
  }

  > .has-background {
    border-radius: $border-radius-md;
    // padding: 10px;

    .wp-block-group__inner-container {
      padding: 10px;
    }
  }
}

.wp-block-media-text {
  img {
    height: auto;
  }

  .wp-block-media-text__media {
    @media (max-width: 600px) {
      margin-bottom: 1.5rem;
    }
  }

  // .wp-block-media-text__media {
  //   margin: 10px ($grid-gutter-width / 2);
  //   margin-left: -$grid-gutter-width / 2;
  //   // min-height: 500px !important;

  //   @include media-breakpoint-down('md') {
  //     margin: 0 auto;
  //   }
  // }

  // &.has-media-on-the-right .wp-block-media-text__media {
  //   margin-right: -$grid-gutter-width / 2;
  //   margin-left: $grid-gutter-width / 2;

  //   @include media-breakpoint-down('md') {
  //     margin: 0 auto;
  //   }
  // }
}

.wp-block-buttons a {
  border-radius: $btn-border-radius-lg;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5em 1em;
}

// Custom Blocks
.wp-block-contributors-carousel {
  padding: 15px 0;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &.alignfull {
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
  }

  .contributors-carousel.flickity-resize {
    .contributors-carousel-item {
      height: 100%;

      > .card {
        height: 100%;
      }
    }
  }

  .contributors-carousel:not(.flickity-enabled) {
    display: flex;
    overflow-x: auto;
  }

  .contributors-carousel-item {
    width: 66.667%;
    margin: 0 15px;
    padding: 30px 0;

    @include media-breakpoint-up('md'){
      width: 33.333%;
    }

    @include media-breakpoint-up('lg'){
      width: 25%;
    }

    .card-body {
      > * {
        width: 100%;
      }
    }

    .carousel-thumb {
      border-radius: $border-radius-md;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .carousel-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: map-get($theme-colors, 'dark');

      &::after {
        content: "\f061";
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        color: map-get($theme-colors, 'primary');
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
      }

      &:hover {
        color: map-get($theme-colors, 'primary');
        text-decoration: none;
      }
    }
  }
}

// Typography
.is-style-line {
  @extend %uppercase;

  color: map-get($theme-colors, 'secondary');
  border-bottom: 4px solid map-get($theme-colors, 'yellow');
  margin-bottom: 1rem;

  .entry-content &,
  .page-content &,
  .front-page-content & {
    display: flex;
    align-items: center;
    border-bottom: none;

    &::after {
      @extend .section-header-border;

      content: "";
      display: block;
      flex: auto;
      margin-left: 0.5rem !important;
    }
  }
}

.is-style-uppercase {
  @extend %uppercase;
}

.has-lg-font-size {
  @extend .lead;
}

.has-sm-font-size {
  font-size: 1rem;
}

// stylelint-disable selector-list-comma-newline-after
.entry-content,
.page-content {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-bottom: 1.375rem;
  }

  ul {
    padding: 0;

    li {
      list-style-position: inside;
    }
  }

  > p:first-child {
    margin-top: 1.375rem;
  }
}
