.content-info {
  .contributor-container {
    @include media-breakpoint-up('lg') {
      background-color: white;
    }
  }

  .contributor-section {
    position: relative;
    padding-top: $h2-font-size;

    @include media-breakpoint-down('lg') {
      margin-top: 60px;
    }
  }

  .acknowledgement {
    padding: 0.6rem 0;
    font-size: 1rem;
  }

  .pill-header {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  .copyright-info {
    @extend %uppercase;

    font-size: 1rem;

    a {
      color: $nav-primary-link-color;
      text-decoration: underline;

      @include hover-focus() {
        color: $nav-primary-link-color;
        text-decoration: none;
      }
    }

    .list-inline-item {
      margin-right: 2rem;
    }
  }
}

.nav-footer {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);

  .nav-link {
    @extend %uppercase;

    color: $nav-primary-link-color;
    text-decoration: none;
    // font-size: 1rem;
    font-weight: 700;

    @include hover-focus() {
      color: $nav-primary-link-hover-color;
    }

    &.disabled {
      color: $nav-primary-link-disabled-color;
    }
  }
}

.lw-tweet-section {
  padding: map-get($spacers, 5) 0;
  background-image: url('../images/bg-topographic-light.png');
  background-size: cover;

  @include media-breakpoint-up('xl') {
    background-image: url('../images/bg-topographic-light@2x.png');
  }

  #ctf {
    overflow: visible;
    padding-bottom: 1px;
    padding-top: 1px;

    .ctf-header {
      border-bottom: none;
      padding-top: 0;
      padding-bottom: map-get($spacers, 4);

      .ctf-header-name,
      .ctf-header-no-bio {
        color: $dark;
        font-size: $h3-font-size;
        font-weight: 800;
        text-transform: uppercase;
        padding-top: 0 !important;
      }
    }

    .ctf-no-bio .ctf-header-text {
      padding-top: 5px;

      .ctf-header-name {
        vertical-align: middle;
      }
    }

    .ctf-tweets {
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        gap: $grid-gutter-width;
        width: 100%;
      }

      .ctf-item {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        padding: 10px;
        margin-bottom: 1rem;
        background-color: white;
        border-radius: $border-radius-md;
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }

        .ctf-author-box {
          flex-shrink: 0;
        }

        .ctf-tweet-content {
          flex-grow: 1;
        }

        .ctf-tweet-actions {
          flex-shrink: 0;
        }
      }
    }

    .ctf-corner-logo {
      display: none;
    }

    .ctf-author-avatar {
      float: none;
      position: absolute;
      top: 10px;
      left: 10px;
      clear: none;
      margin: 0;

      img {
        border-radius: $border-radius-sm;
      }
    }

    .ctf-author-name {
      line-height: 1.2;
    }

    .ctf-tweet-text {
      font-size: 0.875rem;
      color: $dark;

      a {
        color: mix(black, $dark, 10%);
      }
    }
  }
}
