// Use wherever a fixed position is declared to adjust for that pesky WP admin bar.
// https://gist.github.com/TheHeat/40c6748059f218ca0357
@mixin wp-admin-bump {
  .admin-bar & {
    margin-top: 46px;

    @media screen and (min-width: 782px) {
      margin-top: 32px;
    }
  }
}
