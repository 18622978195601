/**
 * Global
 */

html {
  background-color: map-get($theme-colors, 'primary');
}

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.container-fluid {
  max-width: 1410px;
}

.main {
  margin-top: 2rem;
}

a {
  color: map-get($theme-colors, 'primary');
}

// Border Radius Utilities
.br-md {
  border-radius: $border-radius !important;
}

.br-sm {
  border-radius: $border-radius-sm !important;
}

.br-lg {
  border-radius: $border-radius-lg !important;
}

.br-xl {
  border-radius: $border-radius-xl !important;
}

hr {
  border: 1px solid $hr-border-color;
  height: 1px;
  border-radius: 3px;
  background-color: $hr-border-color;
}

// Modal/Close Icon Override
.close {
  opacity: 1;
}

// Iframe Embed styling
iframe {
  display: block;
  max-width: 100%;
  border-radius: $border-radius-sm;
}

/**
 * Typography
 */
%uppercase {
  text-transform: uppercase;
  letter-spacing: 0.0325em;
}

.h1,
h1 {
  @include media-breakpoint-down("md") {
    font-size: 2.5rem;
  }
}

.list-sources {
  font-size: 0.875rem;
  list-style: none;
  padding-left: 0;

  li {
    @extend %uppercase;

    position: relative;
    padding-left: 1.75rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.5rem;
      left: 0.25rem;
      width: 1rem;
      height: 4px;
      border-radius: 2px;
      background-color: $hr-border-color;
    }
  }
}

.meta {
  @extend %uppercase;

  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: mix(black, map-get($theme-colors, 'primary'), 15%);

  .card & {
    margin-bottom: 0;
  }
}

.caption {
  @extend %uppercase;

  margin-top: 0.5rem;
  font-size: 0.875rem;
  text-align: center;
  color: $text-muted;
}

.pill-header {
  display: inline-block;
  border-radius: 2.5em;
  text-transform: uppercase;
  padding: 0.375em 1em;
}

// .lead {
//   line-height: $lead-line-height;
// }

.bg-orange {
  a {
    color: white;
    text-decoration: underline;
  }
}

/**
 * Wildlife Grid Filters
 */
.wildlife-grid-filters {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;

  > .btn {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;

    @supports selector(:first-of-type) and selector(:last-of-type) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include media-breakpoint-down("lg") {
    flex-wrap: wrap;
    gap: 1rem 0;
    justify-content: center;
  }
}

.wildlife-grid-pager {
  display: flex;
  align-items: center;
  margin-left: auto;

  > .btn {
    min-width: 1.75rem;

    @supports selector(:first-of-type) and selector(:last-of-type) {
      margin-left: 0.125rem;
      margin-right: 0.125rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include media-breakpoint-down("lg") {
    margin-left: 0;
    flex-wrap: wrap;
    gap: 1rem 0;
    justify-content: center;
  }
}

.wildlife-grid-prev-next {
  margin-left: 2rem;

  .btn-prev-next {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .btn-prev-next:first-child {
    border-right: 2px solid rgba(map-get($theme-colors, 'dark'), 0.4);
    margin-right: -2px;
  }

  @include media-breakpoint-down("lg") {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }
}

.wildlife-grid-item {
  margin-bottom: $grid-gutter-width;
}

.wildlife-summary-link {
  text-align: left;
  text-transform: none;
  text-decoration: none;
  letter-spacing: normal;

  i {
    font-size: 1.5em;
    margin-left: 0.5rem;
    color: $link-color;
  }
}

.wildlife-summary-title {
  line-height: 1;
}

/**
 * Single Wildlife
 */
.wildlife-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.625rem;

  .wildlife-gallery-grid {
    position: relative;
    padding: 0;

    .wildlife-gallery-grid-icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      color: white;
    }

    &:hover,
    &:focus {
      .wildlife-gallery-grid-icon {
        color: map-get($theme-colors, 'yellow');
      }
    }
  }

  .wildlife-gallery-thumbnail {
    width: 100%;
    height: auto;
  }
}

.wildlife-gallery-carousel {
  opacity: 0;
  transition: opacity 0.4s;

  &.flickity-enabled {
    opacity: 1;
  }

  .carousel-cell {
    // height: 800px;
    margin: 0 0.625rem;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  .carousel-cell-image {
    transition: opacity 0.4s;
    opacity: 0;
    height: 80vh;
    width: auto;

    @include media-breakpoint-down(1024px) {
      height: auto;
      width: 77vw;
    }
  }

  .carousel-cell-image.flickity-lazyloaded,
  .carousel-cell-image.flickity-lazyerror {
    opacity: 1;
  }
}

.wildlife-lightbox {
  .modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .modal-content {
    border-radius: 0;
  }

  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;

    &.btn-lg {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
  }
}

.wildlife-summary {
  margin-top: 1rem;
}

.wildlife-view {
  width: 100%;
}

.extras-content {
  width: 100%;
}

.section-border {
  height: 5px;
  border-radius: 3px;
  background-color: map-get($theme-colors, 'yellow');
}

.wildlife-views-controls {
  display: flex;
  align-items: center;

  .section-border {
    flex: auto;
  }

  .btn-group-toggle {
    letter-spacing: 0.75rem;
  }
}

.list-accounts .rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.5rem;
  min-width: 3.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

//Wildlife Card sizing
.wildlife-grid-item,
.wildlife-shuffle-item-stack {
  @include media-breakpoint-up('sm') {
    height: 240px;
  }

  @include media-breakpoint-up('md') {
    height: 320px;
  }

  @include media-breakpoint-up('lg') {
    height: 400px;
  }

  @include media-breakpoint-up('xl') {
    height: 480px;
  }

  .card {
    @include media-breakpoint-up('sm') {
      height: 100%;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 0;

      @include media-breakpoint-between('sm', 'md') {
        padding: 0 0.875rem 0.875rem;
      }

      h3 {
        @include media-breakpoint-only('md') {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }

        @include media-breakpoint-only('sm') {
          font-size: 1rem;
          margin-bottom: 0.25rem;
        }
      }

      .meta {
        @include media-breakpoint-only('sm') {
          font-size: 0.625rem;
        }
      }
    }
  }
}

.wildlife-shuffle-item-stack {
  &:not(:first-child) {
    @include media-breakpoint-down('xs') {
      display: none;
    }
  }
}

// Shuffling
#wildlifeShuffle {
  transition: height 0.1s ease-in-out;
}

.wildlife-shuffle {
  transform: translateZ(0);
}

.wildlife-shuffle-item {
  transition: transform 0.2s ease-in-out;
  opacity: 1;

  &.stack-bottom {
    opacity: 0.6;
    transform: translateY(10px)
  }

  &.stack-top {
    // opacity: 1;
    transform: translateY(0);
    position: absolute;
    top: 0;
    left: $grid-gutter-width / 2;
    right: $grid-gutter-width / 2;
    z-index: 1000;

    &:first-child {
      z-index: 1005;
    }
  }
}

// Instagram Feed by Smash Balloon
.instagram-feed {
  //...
}

.instagram-feed-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.625rem;

  // Overrides
  width: 100%;
  float: none;

  .instagram-feed-item {
    border-radius: $border-radius-lg;

    // Overrides
    float: none;
    width: 100%;
  }
}

.contributor-logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.625rem;

  > .contributor-logo {
    display: flex;
    align-items: center;
    padding: 10px;

    > a {
      display: block;
    }
  }

  .img-logo {
    width: 100%;
    height: auto;
    filter: grayscale(100%);

    &:hover {
      filter: none;
    }
  }
}

// Lazyloading
img[data-ll-status] {
  opacity: 0;
  transition: opacity 0.6s ease-in;

  &:not([src]):not([srcset]) {
    visibility: hidden;
  }

  &.lazyloaded {
    opacity: 1;
  }
}
