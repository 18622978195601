.front-page-header {
  .instagram-feed-container {
    margin: -10px;
  }

  #sb_instagram {
    .sbi_photo_wrap {
      border-radius: $border-radius-md;
    }
  }
}

.front-page-header-media-container {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  .btn {
    position: absolute;
    left: map-get($spacers, 3);
    bottom: map-get($spacers, 3);
  }

  .front-page-header-video,
  .front-page-header-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.front-page-header-cta {
  display: flex;
  flex-direction: column;

  .front-page-header-btn {
    margin-top: auto;
    margin-right: auto;
  }

  p {
    @include media-breakpoint-up(lg) {
      font-size: $display4-size;
      font-weight: $display4-weight;
    }
  }
}
