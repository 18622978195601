/**
 * Button
 */

%btn-custom {
  @extend %uppercase;
}

.wp-block-button {
  &__link {
    @extend %btn-custom;

    font-size: $btn-font-size;
    line-height: $btn-line-height;
    padding-bottom: $btn-padding-y;
    padding-left: $btn-padding-x;
    padding-right: $btn-padding-x;
    padding-top: $btn-padding-y;

    &:hover {
      text-decoration: none;
    }
  }

  &.is-style-outline {
    .wp-block-button__link {
      @include button-outline-variant(
        map-get($theme-colors, 'primary'),
        map-get($theme-colors, 'light')
      );
    }
  }

  &.is-style-solid {
    .wp-block-button__link {
      @include button-variant(
        map-get($theme-colors, 'primary'),
        map-get($theme-colors, 'light')
      );
    }
  }
}

/**
 * Bootstrap Overrides
 */

.btn {
  @extend %btn-custom;
}

.btn-filter {
  @include button-variant(
    map-get($theme-colors, 'white'),
    map-get($theme-colors, 'dark')
  );

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: map-get($theme-colors, 'white');
    background-color: map-get($theme-colors, 'secondary');
  }
}

// I don't know if this is going to work long term...
[class^="btn-outline-"] {
  border-width: 2px;
}

.btn-white {
  color: map-get($theme-colors, 'primary');
  // border: 1px solid $light;
}

/**
 * Button
 */

.btn-prev-next {
  @extend %btn-custom;

  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: $btn-prev-next-color;
  text-decoration: none;

  &,
  &.btn-sm,
  &.btn-lg {
    border-radius: 0;
  }

  .fa-icon {
    @include transition($btn-transition);

    font-size: 120%;
    color: rgba(map-get($theme-colors, 'dark'), 0.4);

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }

  &:hover {
    color: $btn-prev-next-hover-color;

    .fa-icon {
      color: $btn-prev-next-hover-color;
    }
  }

  &:disabled,
  &.disabled {
    color: $btn-prev-next-disabled-color;
  }
}
