.entry-content {
  ul {
    padding-left: 0;

    &:not(.list-unstyled) {
      li {
        &::marker {
          content: none;
          display: none;
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.5rem;
          width: 1rem;
          height: 5px;
          border-radius: 3px;
          background-color: $medium;
        }
      }
    }
  }

  p + .section-header {
    margin-top: 2rem;
  }
}

.page-content {
  background-color: white;
  border-radius: $border-radius-lg;
  padding: (2.5rem + map-get($spacers, 3)) ($grid-gutter-width / 2) map-get($spacers, 3);
}

.front-page-content {
  padding: 0 ($grid-gutter-width / 2);
}

.extras-content {
  @include media-breakpoint-down('lg') {
    margin-bottom: 1rem;
  }
}
