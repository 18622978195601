/**
* External
*/

@import '~bootstrap/scss/bootstrap';

/**
* This is here instead of in variables.scss because of
* @link https://github.com/plentycode/sass-export/issues/59
*/
$fa-font-path: "../fonts";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
// @import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/solid";
// @import "~@fortawesome/fontawesome-pro/scss/regular";

@import "~flickity/css/flickity.css";
