.wildlife-map {
  background-color: white;
  border-radius: $border-radius-lg;
}

.wildlife-map-panzoom-container {
  position: relative;
  background-color: map-get($theme-colors, 'light');
  border-radius: $border-radius;
  // margin: 0.625rem;
  height: 100%;

  min-height: 480px;

  @include media-breakpoint-up('lg') {
    margin-left: 0;
    min-height: 640px;
  }
}

.wildlife-map-panzoom-element {
  position: relative;
  height: 100%;
  pointer-events: none;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    div,
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.wildlife-map-panzoom-controls {
  position: absolute;
  display: grid;
  gap: 0.3125rem;
  z-index: 100;
  top: 10px;
  right: 10px;

  .btn {
    border-radius: $border-radius-sm;
  }
}

.wildlife-map-controls {
  margin-bottom: 44px + 10px;
  margin-left: -1.875rem;
  margin-right: -1.875rem;

  .flickity-button {
    bottom: -44px - 10px;
    top: auto;
    transform: none;
    z-index: 10;
  }

  .flickity-page-dots {
    counter-reset: a;
    bottom: -44px - ((44px - 30px) / 2);
  }

  .flickity-page-dots .dot {
    width: 30px;
    height: 30px;
    margin: 0 4px;
    background: map-get($theme-colors, 'light');
    opacity: 1;

    &::after {
      content: counter(a);
      counter-increment: a;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: map-get($theme-colors, 'dark');
    }

    &.is-selected {
      background: map-get($theme-colors, 'primary');

      &::after {
        color: white;
      }
    }
  }
}

.wildlife-map-buttons {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.625rem;
  padding: 0.5rem 1.875rem;
  // margin-top: 1.125rem;
}

.wildlife-map-button {
  border-radius: $border-radius;
  padding: 0;

  &.active {
    background-color: white !important;
    box-shadow: 0 0 0 0.2rem $primary !important;
  }
}

.wildlife-map-scrollable {
  height: 524px;
  overflow-y: scroll;
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
}
