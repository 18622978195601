.section-header {
  // Section Header Wrapper
  color: map-get($theme-colors, 'yellow');
  margin-bottom: 1.125rem;
}

.section-header-icon {
  font-size: 1.375rem;
}

.section-header-icon,
.section-header-title {
  margin-right: 0.5rem;
}

.section-header-title {
  &,
  > * {
    @extend %uppercase;

    color: map-get($theme-colors, 'secondary');
    margin-bottom: 0;
  }
}

.section-header-border {
  padding: 0;
  margin: 0;
  border-color: currentColor;
  border-top-width: 0;
  height: 5px;
  border-radius: 3px;
  background-color: map-get($theme-colors, 'yellow');
}
