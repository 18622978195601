/**
 * Fonts
 * TODO: obtain license details
 */

/* Darwin Pro Round - Light */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/los_andes_-_darwin_pro_rd_light-webfont.woff2') format('woff2');
}

/* Darwin Pro Round - Light Italic */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/los_andes_-_darwin_pro_rd_light_it-webfont.woff2') format('woff2');
}

/* Darwin Pro Round - Regular */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/los_andes_-_darwin_pro_rd_regular-webfont.woff2') format('woff2');
}

/* Darwin Pro Round - Regular Italic */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/los_andes_-_darwin_pro_rd_regular_it-webfont.woff2') format('woff2');
}

/* Darwin Pro Round - Bold */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/los_andes_-_darwin_pro_rd_bold-webfont.woff2') format('woff2');
}

/* Darwin Pro Round - Bold Italic */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/los_andes_-_darwin_pro_rd_bold_it-webfont.woff2') format('woff2');
}

/* Darwin Pro Round - Black */
@font-face {
  font-family: 'Darwin Pro Round';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/los_andes_-_darwin_pro_rd_black-webfont.woff2') format('woff2');
}
