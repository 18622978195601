/**
 * Header
 */

.brand {
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.brand-logo {
  width: 90px;
  height: auto;
}

.brand-wordmark {
  //...
}

.banner {
  margin-top: 0.625rem;

  .nav-primary .nav-link,
  .brand,
  .btn-link {
    color: $nav-primary-link-color;
    text-decoration: none;
    text-transform: uppercase;

    @include hover-focus() {
      color: $nav-primary-link-hover-color;
    }

    &.disabled {
      color: $nav-primary-link-disabled-color;
    }
  }

  .nav-primary .nav-link {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .list-inline {
    margin-bottom: 0;
  }
}

.menu-drawer {
  @include wp-admin-bump();

  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: map-get($theme-colors, 'primary');
  z-index: $zindex-tooltip + 10;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out 0.1s;

  .menu-active & {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out 0.2s;
  }
}

.nav-drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .nav-link {
    font-size: $display1-size;
    line-height: $display-line-height;
    color: $nav-primary-link-color;

    @include hover-focus() {
      color: $nav-primary-link-hover-color;
    }

    &.disabled {
      color: $nav-primary-link-disabled-color;
    }
  }

  .btn-menu {
    position: fixed;
    top: 0;
    right: 0;
    padding: 1rem;
  }

  .nav {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .list-accounts {
    padding: 1rem;
    margin-bottom: 1rem;
    // justify-self: flex-end;
  }
}

.nav-child-menu {
  list-style-type: none;
  padding: 0;
}

.menu-item-has-children {
  position: relative;

  > .nav-child-menu {
    display: none;
    position: absolute;
    z-index: 1000;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $dark;
    border-radius: $border-radius-sm;
    padding: 20px;

    &::after {
      content: "";
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $dark;
    }

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .menu-item {
      text-align: center;
    }
  }

  &:hover,
  &.hover,
  &:focus-within {
    > .nav-child-menu {
      display: block;
    }
  }
}
