.breadcrumbs {
  @extend %uppercase;

  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  color: $breadcrumbs-static-color;

  a {
    color: $breadcrumbs-link-color;
    text-decoration: none;

    &:hover {
      color: $breadcrumbs-link-hover-color;
      text-decoration: none;
    }
  }
}
